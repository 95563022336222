/*================================================================================
	Item Name: InfoCoin - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.bookmark-wrapper .navbar-nav {
  display: none;
}

.preloader_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
}

.brand-logo .text-primary {
  display: flex;
  align-items: center;
  color: #000 !important;
}

.login_form {
  display: flex;
  justify-content: center;
}

.logo_center {
  justify-content: center !important;
  padding-left: 0 !important;
}

/* Video list */

.video_col {
  max-height: 40px;
}

.video_edit {
  width: 100%;
  min-height: 200px;
  border-radius: 20px;
  border: 2px solid #fa8200;
}

.video_edit video {
  border-radius: 20px;
}

/* Moderation tab */
.margin-top-comment {
  margin-top: 20px;
}

/* Video view */
.video_view {
  width: 40%;
  min-height: 110px;
  border-radius: 20px;
  border: 2px solid #fa8200;
}

.video_view video {
  border-radius: 20px;
}

/* User list */
.app-user-list {
  background: #fff;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 0.428rem;
  padding: 10px;
  margin-bottom: 20px;
}

.app-user-list .users_list > li {
  list-style-type: none;
}

/* General tab Account */
.fullwidth_span {
  width: 100%;
  display: flex;
}

.hide {
  display: none;
}

.avatar-user {
  margin-right: 1rem;
}

.account-avatar {
  margin-right: 1rem;
  margin-top: 1.2rem;
}

.dark-layout .app-user-list {
  background: #283046;
}

.hidden_col {
  overflow: hidden;
}

.input_checked {
  margin-left: 1rem;
}

.btn_start {
  justify-content: flex-start;
}

.btn_add_cat {
  margin-left: 1.5rem;
}

.cat_avatar {
  max-width: 100%;
  max-height: 100px;
}

.del_prev {
  content: "close";
}

.title_upd_cat {
  margin-bottom: 1rem;
}

.max_width {
  width: 100%;
}

.userinfo_avatar {
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.role_col {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 15px;
}

.role_col > .col-sm-6 {
  padding: 0px;
  display: flex;
  justify-content: center;
}

.table-name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  color: #6e6b7b;
  font-weight: bold;
  font-style: 0.857rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  width: 100%;
  padding: 0.72rem 1.5rem;
  background-color: #f3f2f7;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #ebe9f1;
  border-top-left-radius: 0.428rem;
  border-top-right-radius: 0.428rem;
}

.dark-layout {
  .table-name {
    background: #343d55 !important;
    border-color: #3b4253 !important;
    color: #b4b7bd !important;
  }
}

.ace_tooltip {
  background-image: none !important;
  border: 0 !important;
  border-radius: 0.357rem !important;

  b {
    font-size: 18px;
  }
}

.ace_editor {
  font: 18px / normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
}